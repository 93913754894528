<div
  class="product-card"
  [ngClass]="product.stock.quantity < 1 ? 'disabled' : ''"
  (click)="navigate()"
>
  <a  class="product-card__img-container">
    <div
      *ngIf="product?.reviews?.length || product?.rate"
      class="product-card__rate-star"
    >
      <img src="assets/img/svg/star-fill.svg" />
      <p class="product-card__rate-star__rate">
        {{ getProductRating(product) }}
      </p>
    </div>
    <img
      *ngIf="lazyImage"
      loading="lazy"
      [src]="productImage"
      [alt]="product.name"
    />
    <img *ngIf="!lazyImage" [src]="productImage" [alt]="product.name" />
  </a>
  <div class="info">
    <p class="product-card__brand">{{ product.brand }}</p>
    <a [routerLink]="'/loja/products/' + product.slug">
      <h2
        class="product-card__name"
        [title]="product.name"
        ellipsis
        [ellipsis-content]="product.name"
      ></h2>
    </a>
    <div class="product-card__price-container" *ngIf="!featuredProduct">
      <p
        *ngIf="product.price?.subscriberPrice"
        class="product-card__price product-card__price--new"
      >
        <span class="product-card__price--label bold">Clube:</span>
        <span class="product-card__price--value bold">{{
          product.price?.subscriberPrice | currency: 'BRL'
        }}</span>
      </p>
      <div class="product-card__price-container__column">
        <p class="product-card__price__no-club flex-row">
          <span>Sem clube:</span>
          <span>{{ product.price?.price | currency: 'BRL' }}</span>
        </p>
        <p class="product-card__price__original-value flex-row">
          <span class="product-card__price__original-value--line-through"
            >{{ product.price?.fullPrice | currency: 'BRL' }}
          </span>
          <span class="product-card__label discount">
            {{ product.price?.subscriberDiscountPercentage }}% OFF
          </span>
        </p>
      </div>
    </div>
    <div (click)="navigate()" class="product-card__button-container">
      <button class="product-card__button-container__button" [class.product-card__button-container__button--disabled]="product.stock.quantity < 1">
        <img *ngIf="product.stock.quantity > 0" src="assets/img/svg/sacola.svg" alt="ícone sacola">
        <p class="product-card__button-container__button__text">{{ product.stock.quantity < 1 ? 'Sem estoque' : 'Comprar' }}</p>
      </button>
    </div>
  </div>
</div>
