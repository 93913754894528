<div id="glam-home">
    <main>
        <section>
            <glam-slider-banner [bannerItems]="banners" [isMobile]="isMobile" *ngIf="banners"></glam-slider-banner>
        </section>

        <section>
            <glam-categories></glam-categories>
        </section>

        <section>
            <glam-categories-section></glam-categories-section>
        </section>

        <section>
            <glam-social-proof [social]="social" [isTablet]="isTablet"></glam-social-proof>
        </section>

        <section>
            <components-b4a-values
                [values]="companyValues"
                link="/about-us"
                [newTab]="false"
            ></components-b4a-values>
        </section>

        <section>
            <glam-reviews
                [reviewedProducts]="reviewedProducts"
                [reviewPoints]="reviewPoints"
            ></glam-reviews>
        </section>

        <section>
            <glam-instagram-carousel [innerWidth]="innerWidth"></glam-instagram-carousel>
        </section>

        <section>
            <glam-press></glam-press>
        </section>

        <section>
            <components-b4a-newsletter
                [projectId]="this.projectId"
                [source]="this.source"
            ></components-b4a-newsletter>
        </section>
    </main>
</div>
<section class="modal">
    <ng-template
        #content
        let-modal
    >
        <div class="modal-body">
            <a
                type="button"
                class="close-modal"
                (click)="modal.dismiss('Cross click')"
            >
                <img
                    src="assets/img/svg/close-modal.svg"
                    alt="Close"
                >
            </a>
            <img
                class="banner-modal"
                [src]="innerWidth > 768 ? 'assets/img/modal-desktop.webp' : 'assets/img/modal-mobile.webp'"
                alt
            >
            <div class="aside-container">
                <div *ngIf="show">
                    <h1>Que tal 15%OFF para experimentar?</h1>
                    <p>
                        Cadastre aqui e ganhe 15% de desconto no site em sua primeira compra. Ah!
                        <strong>
                            E com qualquer assinatura do Clube da glam (a partir de R$9,90 por mês)
                        </strong>
                        você consegue 40% de desconto em produtos Glam Beauty. Demais, né? ;)
                    </p>
                    <form
                        [formGroup]="formLead"
                        (ngSubmit)="onSubmit()"
                        novalidate
                    >
                        <div
                            class="input-box"
                            id="input-form"
                        >
                            <input
                                [ngClass]="getClass(formLeadControl.name)"
                                id="name"
                                type="text"
                                formControlName="name"
                                placeholder="Digite seu nome"
                            >
                            <small
                                class="danger"
                                *ngIf="(formLeadControl.name.dirty || submitted) && formLeadControl.name.errors?.required"
                            >
                                Nome é obrigatório
                            </small>
                            <small
                                class="danger"
                                *ngIf="formLeadControl.name.touched && formLeadControl.name.errors?.pattern"
                            >
                                Nome inválido
                            </small>
                        </div>
                        <div
                            id="input-form"
                            class="input-box"
                        >
                            <input
                                [ngClass]="getClass(formLeadControl.email)"
                                id="email"
                                type="email"
                                formControlName="email"
                                placeholder="Digite seu e-mail"
                            >
                            <small
                                class="danger"
                                *ngIf="(formLeadControl.email.touched || submitted) && formLeadControl.email.errors?.required"
                            >
                                E-mail é obrigatório
                            </small>
                            <small
                                class="danger"
                                *ngIf="formLeadControl.email.touched && formLeadControl.email.errors?.pattern"
                            >
                                E-mail inválido
                            </small>
                        </div>
                        <button
                            type="submit"
                            class="btn-purple-round btn-submit"
                            [disabled]="loadingNewsletter || formLead.invalid"
                        >
                            {{ loadingNewsletter ? 'Aguarde' : 'Receber cupom' }}
                        </button>
                    </form>
                </div>
                <div
                    class="coupon-container"
                    *ngIf="!show"
                >
                    <h1>Oba! Cupom!</h1>
                    <p>
                        Use o cupom abaixo e garanta 15%OFF na sua primeira compra.
                    </p>
                    <img
                        class="coupon-border"
                        [src]="innerWidth > 768 ? 'assets/img/border-coupon.svg' : 'assets/img/border-coupon-mobile.svg'"
                        alt="border coupon"
                    >
                    <p class="user-email">
                        Esse desconto só é válido para o e-mail:
                        <strong>{{ user_email }}</strong>
                    </p>

                    <a
                        target="_blank"
                        href="https://glam.com.br/loja/collections/glambeauty"
                    >
                        <button
                            type="submit"
                            class="btn-purple-round btn-submit"
                        >
                            Aproveitar
                        </button>
                    </a>

                </div>

            </div>
        </div>
    </ng-template>
</section>
