<div id="social-proof-card">
    <div class="card">
      <img [src]="social.imagePath" [alt]="social.title"/>
      <div class="card__body">
        <div class="title-container">
          <img class="svg-quot" src="assets/img/quot.svg" alt="aspas">
          <p class="title">{{social.title}}</p>
        </div>
        <p class="description">
          {{ social.description }}
        </p>
      </div>
    </div>
  </div>
