export const environment = {
  apiUrl: "https://subscription-api-prod.glambox.com.br/",
  production: true,
  firebase: {
    apiKey: 'AIzaSyCJf1tkjIN1I1ZegazckSiwS0ik4Lequwo',
    authDomain: 'b4aprodution.firebaseapp.com',
    projectId: 'b4aprodution',
    storageBucket: 'b4aprodution.appspot.com',
    messagingSenderId: '458755317337',
    appId: '1:458755317337:web:fc98697332fada8d021b48',
    measurementId: 'G-T6E6L3J73M',
  },
  elasticSearch: {
    url: "https://e4a30e39227142719915dfb4bd5240b5.us-central1.gcp.cloud.es.io:443",
    credential: "MnBJVHY0Y0JtbEZ2dFBZRE82NGQ6WHdXQ1ZwTkhRQUNvS2s5WTNGSUNCUQ=="
  },
  hasura: {
    endpoint: "https://hasura-products-zitr2bdv2q-rj.a.run.app/v1/graphql",
    adminSecret: "f0e3791d4521b1258b954aaf81898dbf"
  },
  firebaseRegion: 'southamerica-east1',
  gaTackingId: 'G-DRQND1G9EJ',
  instaToken:
    'IGQVJVbDB3bUdpYmEwa0JfVFF0bVFmd2I4UDdEU1hsY3puTzRSQ1JqSWtCV3AwWENmM0lDZAU55XzlPb1ZA2c1NzX056c2w3aTFJUU5WNDg1N2k3dUxKODRmWmxTa0lNV0tkSkFvdllPWlVHaDRxWUliaQZDZD',
  instaApi: 'https://graph.instagram.com/me/media?access_token=',
}
