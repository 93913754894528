import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as componentsB4a from '@infrab4a/components-b4a';
import { Product } from '@infrab4a/connect';

import { carouselReview } from '../../constants/slides';

@Component({
  selector: 'glam-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewsComponent {

  @Input() reviewedProducts: Product[];
  @Input() reviewPoints: number[];
  @Input() loading: boolean;
  innerWidth: number;
  more: boolean = false;
  buttonText: string = "Ver mais avaliações";

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points?: number[] = [];
  position: number = 0;

  @ViewChild('carouselReview')
  set _carouselReview(carouselReview: componentsB4a.B4aCarouselComponent<any>) {
    this.carousel = carouselReview;
    setTimeout(() => {
      this.points = this.reviewPoints;
    });
  }

  carouselTile: componentsB4a.B4aCarouselConfig;

  carouselTileMobile: componentsB4a.B4aCarouselConfig;

  constructor() {
    this.carouselTile = carouselReview;
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    const firstPosition = 0;
    const lastPosition = this.points?.length - 1;
    this.position = myCarousel.currentSlide;
    myCarousel.isFirst = firstPosition === this.position;
    myCarousel.isLast = lastPosition === this.position;
  }
}
