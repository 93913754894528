import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { carouselBanner } from '../../constants/slides';
import { Banner } from '../../models/banner';
import * as componentsB4a from '@infrab4a/components-b4a';

@Component({
  selector: 'glam-slider-banner',
  templateUrl: './slider-banner.component.html',
  styleUrls: ['./slider-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderBannerComponent implements OnInit {
  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel.pointNumbers;
      });
  }

  @Input() bannerItems: Banner[];
  @Input() isMobile = false;

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];
  carouselTile: componentsB4a.B4aCarouselConfig;

  constructor() {
    this.carouselTile = carouselBanner;
  }

  ngOnInit(): void {
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }
}
