import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { LeadModel } from 'src/app/shared/models/lead.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BrandLeadService {
  private readonly ENDPOINT = `https://southamerica-east1-${environment.firebase.projectId}.cloudfunctions.net/saveBrandLead`;

  constructor(
    private http: HttpClient
  ) {}

  registerNewsletter(requestPayload: LeadModel): Promise<any> {
    return this.http
      .post(this.ENDPOINT, { data: requestPayload })
      .pipe(map((res) => res))
      .toPromise();
  }
}
