import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Press } from '../../models/press.model';

@Component({
  selector: 'glam-press-card',
  templateUrl: './press-card.component.html',
  styleUrls: ['./press-card.component.scss'],
})
export class PressCardComponent implements OnInit {

  @Input() press: Press;

  constructor() { }

  ngOnInit(): void {
  }

}
