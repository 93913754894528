<div class="categories-section container">
  <div class="categories-section__categorie">
    <glam-categories-products
      [products]="productsSkincare"
      [title]="'Skincare'"
      [redirectLink]="redirectLinks[0]"
    ></glam-categories-products>
  </div>
  <div class="categories-section__categorie">
    <glam-categories-products
      [products]="productsMake"
      [title]="'Maquiagem'"
      [redirectLink]="redirectLinks[2]"
    ></glam-categories-products>
  </div>

  <ng-container *ngIf="showMoreCategories">
    <div class="categories-section__categorie">
      <glam-categories-products
      [products]="productsPerfume"
      [title]="'Perfumaria'"
      [redirectLink]="redirectLinks[3]"
      ></glam-categories-products>
    </div>
    <div class="categories-section__categorie">
      <glam-categories-products
        [products]="productsBody"
        [title]="'Corpo'"
        [redirectLink]="redirectLinks[1]"
      ></glam-categories-products>
    </div>
    <div class="categories-section__categorie">
      <glam-categories-products
        [products]="productsAccessories"
        [title]="'Acessórios e Kits'"
        [redirectLink]="redirectLinks[4]"
      ></glam-categories-products>
    </div>
  </ng-container>

  <div class="categories-section__button-container">
    <button (click)="getMoreCategoriesProducts()" class="categories-section__button-container__button">
      <p class="categories-section__button-container__button__text">Ver {{ showMoreCategories ? 'menos' : 'mais' }} categorias</p>
    </button>
  </div>
</div>
