<footer class="footer">
    <div class="container footer__container">
      <ng-container *ngIf="!isTablet">
        <div class="footer__container__desktop">
          <div class="footer__container__desktop__glam-menu">
            <img class="footer__container__desktop__glam-menu__logo" src="assets/img/svg/logo.svg" alt="logo glam beauty">
            <div class="footer__container__desktop__glam-menu__menus">
              <div *ngFor="let item of linksGlam" class="footer__container__desktop__glam-menu__menus__menu">
                <p class="footer__container__desktop__glam-menu__menus__menu__title">{{ item.title }}</p>
                <div class="footer__container__desktop__glam-menu__menus__menu__sub-items">
                  <a *ngFor="let subItem of item.links" [href]="subItem.url" target="_blank" [class.footer__container__desktop__glam-menu__menus__menu__sub-items__item--icon]="subItem.icon">
                    <p *ngIf="!subItem.icon" class="footer__container__desktop__glam-menu__menus__menu__sub-items__link">{{ subItem.title }}</p>
                    <img *ngIf="subItem.icon" class="footer__container__desktop__glam-menu__menus__menu__sub-items__icon" [src]="subItem.icon" [alt]="subItem.title">
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="footer__container__desktop__b4a-menu">
            <img class="footer__container__desktop__b4a-menu__logo" src="assets/img/svg/b4a-logo.svg" alt="logo b4a">
            <div class="footer__container__desktop__glam-menu__menus">
              <div *ngFor="let item of linksB4a" class="footer__container__desktop__glam-menu__menus__menu">
                <p class="footer__container__desktop__glam-menu__menus__menu__title">{{ item.title }}</p>
                <div class="footer__container__desktop__glam-menu__menus__menu__sub-items">
                  <a *ngFor="let subItem of item.links" [href]="subItem.url" target="_blank" [class.footer__container__desktop__glam-menu__menus__menu__sub-items__item--icon]="subItem.icon">
                    <p *ngIf="!subItem.icon" class="footer__container__desktop__glam-menu__menus__menu__sub-items__link">{{ subItem.title }}</p>
                    <img *ngIf="subItem.icon" class="footer__container__desktop__glam-menu__menus__menu__sub-items__icon" [src]="subItem.icon" [alt]="subItem.title">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isTablet">
        <div class="footer__container__mobile">
          <div class="footer__container__mobile__glam-menu">
            <img class="footer__container__mobile__glam-menu__logo" src="assets/img/svg/logo.svg" alt="logo glam beauty">
            <div class="footer__container__mobile__glam-menu__menus">
              <div *ngFor="let item of linksGlam" class="footer__container__mobile__glam-menu__menus__menu">
                <div (click)="toggleLinkItem(item)" class="footer__container__mobile__glam-menu__menus__menu__header">
                  <p class="footer__container__mobile__glam-menu__menus__menu__header__title">{{ item.title }}</p>
                  <img src="assets/img/svg/seta-baixo.svg" alt="seta para baixo - expandir menu">
                </div>
                <ng-container *ngIf="item.isOpened">
                  <div class="footer__container__mobile__glam-menu__menus__menu__sub-items">
                    <a *ngFor="let subItem of item.links; let i = index" [href]="subItem.url" target="_blank" [class.footer__container__mobile__glam-menu__menus__menu__sub-items__item--icon]="subItem.icon">
                      <p *ngIf="!subItem.icon" class="footer__container__mobile__glam-menu__menus__menu__sub-items__link" [class.footer__container__mobile__glam-menu__menus__menu__sub-items__link--last-link]="i === item.links.length - 1">{{ subItem.title }}</p>
                      <img *ngIf="subItem.icon" class="footer__container__mobile__glam-menu__menus__menu__sub-items__icon" [src]="subItem.icon" [alt]="subItem.title">
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="footer__container__mobile__b4a-menu">
            <img class="footer__container__mobile__b4a-menu__logo" src="assets/img/svg/b4a-logo.svg" alt="logo b4a">
            <div class="footer__container__mobile__glam-menu__menus">
              <div *ngFor="let item of linksB4a" class="footer__container__mobile__glam-menu__menus__menu">
                <div (click)="toggleLinkItem(item)" class="footer__container__mobile__glam-menu__menus__menu__header">
                  <p class="footer__container__mobile__glam-menu__menus__menu__header__title">{{ item.title }}</p>
                  <img src="assets/img/svg/seta-baixo.svg" alt="seta para baixo - expandir menu">
                </div>
                <ng-container *ngIf="item.isOpened">
                  <div class="footer__container__mobile__glam-menu__menus__menu__sub-items">
                    <a *ngFor="let subItem of item.links; let i = index" [href]="subItem.url" target="_blank" [class.footer__container__mobile__glam-menu__menus__menu__sub-items__item--icon]="subItem.icon">
                      <p *ngIf="!subItem.icon" class="footer__container__mobile__glam-menu__menus__menu__sub-items__link" [class.footer__container__mobile__glam-menu__menus__menu__sub-items__link--last-link]="i === item.links.length - 1">{{ subItem.title }}</p>
                      <img *ngIf="subItem.icon" class="footer__container__mobile__glam-menu__menus__menu__sub-items__icon" [src]="subItem.icon" [alt]="subItem.title">
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <p class="footer__container__address">B4A Serviços de Tecnologia e Comércio S.A. - Avenida Jornalista Roberto Marinho, nº.: 85, 17º Andar (Conjuntos 171 e 172), Cidade Monções - CEP 04576-010 - Cidade de São Paulo, Estado de São Paulo</p>
    </div>
</footer>
