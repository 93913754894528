import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'glam-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss']
})
export class AdviceComponent {
  @Input()
  adviceTest = '';

  @Output()
  closeClick = new EventEmitter();
}
