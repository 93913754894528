import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Product } from '@infrab4a/connect';

@Component({
  selector: 'glam-categories-products',
  templateUrl: './categories-products.component.html',
  styleUrls: ['./categories-products.component.scss']
})
export class CategoriesProductsComponent implements OnInit {

  @Input()
  products: Array<Product>;

  @Input()
  title = '';

  @Input()
  redirectLink = '';

  maxCardsToShow = 5;
  breakpoints = [
    { width: 370, maxCards: 1 },
    { width: 570, maxCards: 2 },
    { width: 768, maxCards: 3 },
    { width: 992, maxCards: 4 },
    { width: 1024, maxCards: 5 },
    { width: 1200, maxCards: 4 },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.getCards();
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.getCards();
  }

  goTo(link: string): void {
    if (isPlatformBrowser(this.platform)) {
      window.open(link, '_blank');
    }
  }

  getCards(): void {
    if (isPlatformBrowser(this.platform)) {
      const windowWidth = window.innerWidth;
      for (const breakpoint of this.breakpoints) {
        if (windowWidth <= breakpoint.width) {
          this.maxCardsToShow = breakpoint.maxCards;
          break;
        }
      }
      if (windowWidth > this.breakpoints[this.breakpoints.length - 1].width) {
        this.maxCardsToShow = 5;
      }
    }
  }

}
