<div
    id="gb-alert-dialog"
    *ngIf="alert$ | async as alert"
>
    <span>{{ alert }}</span>
</div>
<glam-advice *ngIf="showAdvice" [adviceTest]="'Frete grátis acima de R$ 200,00'" (closeClick)="showAdvice = !showAdvice"></glam-advice>
<div [class.header-b4a]="showAdvice">
  <components-b4a-header
    [menu]="menu"
    [logo]="logo"
    [closeButton]="closeButton"
  ></components-b4a-header>
</div>
<div class="router-container" [class.router-container--advice-closed]="!showAdvice">
  <router-outlet></router-outlet>
</div>
<glam-footer [isTablet]="isTablet"></glam-footer>

