import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ResizeAppService implements OnDestroy {
  private resizeObservable$: Observable<Event> | undefined;
  private innerWidthState$: BehaviorSubject<number> =
    new BehaviorSubject<number>(window.innerWidth);
  private destroy$ = new Subject();

  private readonly MOBILE_WIDTH_SIZE = 767;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.innerWidthState$?.unsubscribe();
  }

  get isMobileWidthSize() {
    return window.innerWidth <= this.MOBILE_WIDTH_SIZE;
  }

  get isMobile() {
    const match = window.matchMedia;

    if (match) {
      const mq = match('(pointer:coarse)');

      return mq.matches;
    }

    if (typeof screen.orientation !== 'undefined') {
      return true;
    }

    return false;
  }

  onResize(): Observable<number> {
    this.resizeObservable$ = fromEvent(window, 'resize');

    return this.resizeObservable$.pipe(
      debounceTime(250),
      takeUntil(this.destroy$),
      map(evt => {
        const target = evt.target as Window;

        return target.innerWidth;
      })
    );
  }
}
