import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstaFeedModel } from 'src/app/shared/models/insta-feed.model';
import { environment as ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InstagramFeedService {
  private readonly INSTA_TOKEN = ENV.instaToken;
  private readonly INSTA_API = ENV.instaApi;

  constructor(private _httpClient: HttpClient) {}

  private buildFeedParams(): HttpParams {
    const fields = 'media_url,media_type,caption,permalink';
    const limit = 15;

    const httpParams = new HttpParams()
      .set('fields', fields)
      .set('limit', limit);

    return httpParams;
  }

  getFeed(): Observable<InstaFeedModel[]> {
    const params = this.buildFeedParams();
    const URL = `${this.INSTA_API}${this.INSTA_TOKEN}`;

    return this._httpClient
      .get<{ data: InstaFeedModel[] }>(URL, { params })
      .pipe(map(response => response.data));
  }
}
