import { Component, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { Product } from '@infrab4a/connect';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

import { ProductModel } from '../../models/product.model';

@Component({
  selector: 'glam-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewCardComponent implements OnInit {

  @Input() reviewedProduct: Product;
  ratingAverage: number;
  ratingCount: number;
  star: TemplateRef<unknown>;

  constructor(
    private ngbConfig: NgbRatingConfig,
  ) {
    this.ngbConfig.max = 5;
   }

  ngOnInit() {
    this.ratingCount = this.reviewedProduct?.reviews?.filter((r) => r.status).length || 0;
    this.ratingAverage = this.ratingCount ? (this.reviewedProduct?.reviews?.filter((r) => r.status)
      .reduce((sum, review) => sum += review.rate, 0) / this.ratingCount) : 0;
  }

}
