import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Observable } from 'rxjs';

import { MenuHeaderService } from './core/services/menu-header.service';
import { ResizeAppService } from './core/services/resize-application.service';
import m from '../assets/data/menu.json';
import { Menu } from './shared/models/menu.model';
import { FooterModel } from './shared/models/footer.model';
import data from 'src/assets/data/links-footer.json';
import { Meta } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'glam-beauty';

  alert$: Observable<string> | undefined;
  menu: Menu[] = [];
  logo: string;
  closeButton: string;
  linksB4a: FooterModel[];
  linksBrand: FooterModel[];
  logoFooter: string;
  showAdvice = true;
  isTablet = false;

  @HostListener("window:resize", ["$event"])
  resize(): void {
    this.styleSlider();
  }

  constructor(
    private _resizeAppService: ResizeAppService,
    private _menuHeaderService: MenuHeaderService,
    private _router: Router,
    private gtmService: GoogleTagManagerService,
    private metaTag: Meta,
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  ngOnInit(): void {
    this.styleSlider();
    this.linksB4a = data.b4a;
    this.linksBrand = data.glam;
    this.logoFooter = 'assets/img/logo-white.svg';
    this.menu = m.menu;
    this.logo = 'assets/img/logo.svg';
    this.closeButton = 'assets/img/svg/close.svg';
    this.onResize();
    this.metaTag.addTag({
      property: 'og:image',
      content:
        'https://storage.googleapis.com/b4aprodution.appspot.com/dms/thumb-gb.png'
    });
    this._router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page_view',
          pageUrl: event.url
        };
        this.gtmService.pushTag(gtmTag);
      }
    });    // this._alertDialogService.showAlert('Frete grátis acima de R$ 250,00');
    // this.alert$ = this._alertDialogService.alert$;
  }

  // close() {
  //   this._alertDialogService.closeAlert();
  // }

  private onResize() {
    this._resizeAppService.onResize().subscribe(() => {
      this._menuHeaderService.close();
    });
  }

  styleSlider(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isTablet = window.innerWidth < 768;
    }
  }


}
