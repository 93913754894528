<div class="categories-products">
  <div (click)="goTo(redirectLink)" class="categories-products__header">
    <p class="categories-products__header__title">{{ title }}</p>
    <span class="categories-products__header__arrow">
      <img src="assets/img/svg/circle-arrow-right.svg" alt="ícone seta ir para categoria">
    </span>
  </div>
  <div class="categories-products__products-box">
    <div *ngFor="let product of products | slice: 0:maxCardsToShow; let i = index" class="categories-products__products-box__item">
      <glam-product-card [product]="product"></glam-product-card>
    </div>
  </div>
</div>
