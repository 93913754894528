import { Component } from '@angular/core';

@Component({
  selector: 'glam-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {

  items = [
    {
      text: 'Skincare',
      img: 'assets/img/category-3.png',
      href: 'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-skincare'
    },
    {
      text: 'Corpo',
      img: 'assets/img/category-4.png',
      href: 'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=327.20001220703125&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-corpo'
    },
    {
      text: 'Maquiagem',
      img: 'assets/img/category-5.png',
      href: 'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=519.2000122070312&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-maquiagem'
    },
    {
      text: 'Perfumaria',
      img: 'assets/img/category-6.png',
      href: 'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-perfumaria'
    },
    {
      text: 'Acessórios',
      img: 'assets/img/category-7.png',
      href: 'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=200&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-acessórios'
    },
  ];

  getBackground(img: string): { 'background-image': string } {
    return { 'background-image': 'linear-gradient(360deg,#1C1210 -12.5%,rgba(28,18,16,0) 64.13%),url(' + img + ')'};
  }

}
