<div id="press-component">
  <div class="container">
    <h2>O que estão dizendo sobre a glam</h2>
    <components-b4a-carousel
        #myCarousel
        [inputs]="carouselTile"
        [dataSource]="press"
        (onMove)="onCarouselMove($event)"
    >
        <b4a-tile *b4aCarouselDef="let item; let i = index">
            <glam-press-card [press]="item"></glam-press-card>
        </b4a-tile>
        <ul
            class="dots"
            B4aCarouselPoint
            *ngIf="points?.length > 0"
        >
          <button B4aCarouselPrev class="leftRs">
            <img
              src="assets/img/svg/arrow-left-dark.svg"
              alt="icon anterior"
              [style]="{ width: '10px', height: '18px' }"
            />
          </button>
          <li
              *ngFor="let i of points; let index"
              [class.active]="i==myCarousel.activePoint"
              (click)="myCarousel.moveTo(index)"
          ></li>
          <button B4aCarouselNext class="rightRs">
            <img
              src="assets/img/svg/arrow-right-dark.svg"
              alt="icon próximo"
              [style]="{ width: '10px', height: '18px' }"
            />
          </button>
        </ul>
    </components-b4a-carousel>
  </div>
</div>
