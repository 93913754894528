import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

import { carouselPress } from '../../constants/slides';
import data from 'src/assets/data/press.json';
import { Press } from '../../models/press.model';
import * as componentsB4a from '@infrab4a/components-b4a';

@Component({
  selector: 'glam-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PressComponent {

  press: Press[];
  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel?.pointNumbers;
      });
  }
  carouselTile: componentsB4a.B4aCarouselConfig;

  carouselTileMobile: componentsB4a.B4aCarouselConfig;

  constructor() {
    this.carouselTile = carouselPress;
    this.press = data.press;
   }

   onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }


}
