import { Component, Inject, OnInit } from '@angular/core';
import {
  Category,
  CategoryRepository,
  Product,
  ProductRepository,
  Shops,
  Where,
} from '@infrab4a/connect';
import { CatalogService } from '@infrab4a/connect-angular';

@Component({
  selector: 'glam-categories-section',
  templateUrl: './categories-section.component.html',
  styleUrls: ['./categories-section.component.scss'],
})
export class CategoriesSectionComponent implements OnInit {
  result: any;
  category: Category;
  productsSkincare: Array<Product>;
  productsBody: Array<Product>;
  productsMake: Array<Product>;
  productsPerfume: Array<Product>;
  productsAccessories: Array<Product>;
  showMoreCategories = false;

  redirectLinks = [
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-skincare',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=327.20001220703125&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-corpo',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=519.2000122070312&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-maquiagem',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=0&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-perfumaria',
    'https://glam.com.br/loja/collections/glambeauty?page=1&scrollY=200&profile=false&brandIds=&prices=&genders=feminino&customOptions=categorias-acessórios',
  ];

  constructor(
    private catalogService: CatalogService,
    @Inject('CategoryRepository')
    protected categoryRepository: CategoryRepository,
    @Inject('ProductRepository') private productRepository: ProductRepository
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getCategoriesProducts();
  }

  async getCategoriesProducts(): Promise<void> {
    this.productsSkincare = (
      await this.productRepository.find({
        filters: {
          id: {operator: Where.IN, value: ['4605', '17226', '17227', '8539', '6946']}
        }
      })
    ).data

    this.productsMake = (
      await this.productRepository.find({
        filters: {
          id: {operator: Where.IN, value: ['14890', '15774', '15757', '15935', '15678']}
        }
      })
    ).data
  }

  async getMoreCategoriesProducts(): Promise<void> {
    if (!this.showMoreCategories) {

      this.productsBody = (
        await this.productRepository.find({
          filters: {
            id: {operator: Where.IN, value: ['10732', '3627', '10730', '10731', '14035']}
          }
        })
      ).data;

      this.productsPerfume = (
        await this.productRepository.find({
          filters: {
            id: {operator: Where.IN, value: ['14281', '16439', '16440', '13826', '9230']}
          }
        })
      ).data;

      this.productsAccessories = (
        await this.productRepository.find({
          filters: {
            id: {operator: Where.IN, value: ['7354', '2906', '8398', '13826', '9230']}
          }
        })
      ).data;

      this.showMoreCategories = true;
    } else {
      this.showMoreCategories = false;
    }
  }
}
