import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Utils } from 'src/app/shared/utils/Utils';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'glam-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  isProduction = false;
  innerWidth: number;
  isMobile: boolean;
  companyValues = [
    {
      description: 'Alta Qualidade',
      svgPath: 'assets/img/our_value_1.svg',
    },
    {
      description: 'Vegano e cruelty free<',
      svgPath: 'assets/img/our_value_2.svg',
    },
    {
      description: 'Rotina descomplicada',
      svgPath: 'assets/img/our_value_3.svg',
    },
    {
      description: 'Sem parabenos',
      svgPath: 'assets/img/our_value_4.svg',
    },
  ];

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.stylePage();
  }

  constructor(private meta: Meta,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platform: object
) {
    this.isProduction = ENV.production;
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.stylePage();
    this.titleService.setTitle("Glam Beauty: sobre nós");
    this.meta.addTag({
      name: 'author',
      content: 'B4A'
    });
    this.meta.addTag({
      name: 'description',
      content:
        'Glam Beauty veio pra democratizar a beleza com uma rotina completa de cuidados. Tudo com alta performance e os melhores preços do mercado.'
    });
  }

  handleSlideToSection(section: string) {
    Utils.slideToSection(section);
  }

  stylePage(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isMobile = window.innerWidth < 768;
    }
  }

  get src(): string {
    return this.isMobile ? 'assets/img/banner-about-us-mobile.webp' : '/assets/img/banner-about-us.webp'
  }
}
