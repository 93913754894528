<div id="reviews-component">
    <div class="wrapper container">
        <h2 class="wrapper__title">Avaliações</h2>
            <div
                class="content"
                *ngIf="reviewedProducts?.length > 0"
            >
                <div class="desktop-content">
                    <components-b4a-carousel
                        #carouselReview
                        [inputs]="carouselTile"
                        [dataSource]="reviewedProducts"
                        (onMove)="onCarouselMove($event)"
                    >
                        <b4a-tile *b4aCarouselDef="let item; let i = index">
                            <glam-review-card [reviewedProduct]="item"></glam-review-card>
                        </b4a-tile>
                        <ul
                            class="dots"
                            B4aCarouselPoint
                            *ngIf="points?.length > 0"
                        >
                          <button B4aCarouselPrev class="leftRs">
                            <img
                              src="assets/img/svg/arrow-left-dark.svg"
                              alt="icon anterior"
                              [style]="{ width: '10px', height: '18px' }"
                            />
                          </button>
                          <li
                            *ngFor="let i of points; let index"
                            [class.active]="i==carouselReview.activePoint"
                            (click)="carouselReview.moveTo(index)"
                          ></li>
                          <button B4aCarouselNext class="rightRs">
                            <img
                              src="assets/img/svg/arrow-right-dark.svg"
                              alt="icon próximo"
                              [style]="{ width: '10px', height: '18px' }"
                            />
                          </button>

                        </ul>
                    </components-b4a-carousel>
                </div>
            </div>
    </div>
</div>
