import { Component, Input, OnInit } from '@angular/core';
import data from 'src/assets/data/links-footer.json';

import { FooterModel } from '../../models/footer.model';

@Component({
  selector: 'glam-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input()
  isTablet = false;

  linksB4a: FooterModel[];
  linksGlam: FooterModel[];

  constructor() {
    this.linksB4a = data.b4a;
    this.linksGlam = data.glam;
  }

  ngOnInit(): void {}

  toggleLinkItem(item: FooterModel): void {
    item.isOpened = !item.isOpened;
  }
}
