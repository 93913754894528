<div id="glam-insta-carrousel">
    <h2>
        <a
            href="https://www.instagram.com/useglambeauty"
            target="_blank"
            rel="noopener noreferrer"
        >
            @useglambeauty
        </a>
    </h2>

    <ng-container *ngIf="instaFeed$ | async as instaFeed; else loadingCarousel">
        <div class="container-desktop container">
            <components-b4a-carousel
                #myCarousel
                [inputs]="carouselTile"
                [dataSource]="instaFeed"
                (onMove)="onCarouselMove($event)"
            >
                <b4a-tile *b4aCarouselDef="let item; let i = index">
                    <div class="card">
                        <a
                            [title]="item.caption"
                            [href]="item.permalink"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                [src]="item.media_url"
                                [alt]="item.caption"
                                class="card__image"
                            >
                        </a>
                    </div>
                </b4a-tile>
                <ul
                    class="dots"
                    B4aCarouselPoint
                    *ngIf="points?.length"
                >
                  <button B4aCarouselPrev class="leftRs">
                    <img
                      src="assets/img/svg/arrow-left-dark.svg"
                      alt="icon anterior"
                      [style]="{ width: '10px', height: '18px' }"
                    />
                  </button>
                  <li
                      *ngFor="let i of points; let index"
                      [class.active]="index === myCarousel.activePoint"
                      (click)="myCarousel.moveTo(i)"
                  ></li>
                  <button B4aCarouselNext class="rightRs">
                    <img
                      src="assets/img/svg/arrow-right-dark.svg"
                      alt="icon próximo"
                      [style]="{ width: '10px', height: '18px' }"
                    />
                  </button>
                </ul>
            </components-b4a-carousel>
        </div>

        <div class="container-mobile">
            <div *ngFor="let item of instaFeed; let i = index;">
                <div
                    class="card"
                    *ngIf="i < 6"
                >
                    <a
                        [title]="item.caption"
                        [href]="item.permalink"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            [src]="item.media_url"
                            [alt]="item.caption"
                            class="card__image"
                        >
                    </a>
                </div>
            </div>
        </div>

    </ng-container>

    <ng-template #loadingCarousel>
      <div class="container-desktop container">
        <components-b4a-carousel
            #myCarousel
            [inputs]="carouselTile"
            [dataSource]="instaFeedStaticsImages"
            (onMove)="onCarouselMove($event)"
        >
            <b4a-tile *b4aCarouselDef="let item; let i = index">
                <div class="card">
                    <a
                        [href]="item.permalink"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            [src]="item.image"
                            [alt]="item.alt"
                            class="card__image"
                        >
                    </a>
                </div>
            </b4a-tile>
            <ul
                class="dots"
                B4aCarouselPoint
                *ngIf="points?.length"
            >
              <button B4aCarouselPrev class="leftRs">
                <img
                  src="assets/img/svg/arrow-left-dark.svg"
                  alt="icon anterior"
                  [style]="{ width: '10px', height: '18px' }"
                />
              </button>
              <li
                  *ngFor="let i of points; let index"
                  [class.active]="index === myCarousel.activePoint"
                  (click)="myCarousel.moveTo(i)"
              ></li>
              <button B4aCarouselNext class="rightRs">
                <img
                  src="assets/img/svg/arrow-right-dark.svg"
                  alt="icon próximo"
                  [style]="{ width: '10px', height: '18px' }"
                />
              </button>
            </ul>
        </components-b4a-carousel>
    </div>

    <div class="container-mobile">
        <div *ngFor="let item of instaFeedStaticsImages; let i = index;">
            <div
                class="card"
                *ngIf="i < 6"
            >
                <a
                    [href]="item.permalink"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img
                        [src]="item.image"
                        [alt]="item.alt"
                        class="card__image"
                    >
                </a>
            </div>
        </div>
    </div>
    </ng-template>
</div>
