import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Product, Variant } from '@infrab4a/connect';

@Component({
  selector: 'glam-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit, AfterViewInit {
  @Input() product: Product;
  @Input() featuredProduct = false;
  @Input() showButtonBuy = true;
  @Input() lazyImage = true;
  maxRating = 5;

  variants: Variant[];
  variantsForm;
  discountPercentage: number;

  get productImage(): string {
    if (!this.product.images?.length)
      return '../../../assets/images/no-img-brand.png';

    const image = this.product.images[0];

    if (image.includes('products-images')) return image;

    return image
      .replace('original/', 'medium/')
      .replace(`.${image.split('.').pop()}`, '.webp');
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: object
  ) {}

  async ngOnInit() {}

  ngAfterViewInit() {
    if (this.product?.reviews?.length)
      this.product.reviews = this.product.reviews.filter((r) => r.status);
  }

  getProductRating(product: Product): string {
    if (product.rate) return product.rate.toFixed(1).replace('.', ',');

    const reviews = product?.reviews;

    if (!reviews || !reviews.length) return '0';

    return (reviews.reduce((acc, curr) => acc + curr.rate, 0) / reviews.length)
      .toFixed(1)
      .replace('.', ',');
  }

  navigate() {
    if (isPlatformBrowser(this.platform) && this.product.stock.quantity > 0) {
      window.open('https://glam.com.br/loja/products/' + this.product.slug, 'blank');
    }
  }
}
