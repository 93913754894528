import { isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Category, CategoryRepository, NonFunctionPropertyNames, Product, ProductRepository, ProductReview, ShopSettings, ShopSettingsRepository, Shops, Where } from '@infrab4a/connect';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandLeadService } from 'src/app/core/services/api/brand-lead.service';
import { ResizeAppService } from 'src/app/core/services/resize-application.service';
import { Banner } from 'src/app/shared/models/banner';
import { LeadModel, LeadSourceEnum } from 'src/app/shared/models/lead.model';
import { ResponseNewletter } from 'src/app/shared/models/responseNewletter';
import { Utils } from 'src/app/shared/utils/Utils';
import { environment as ENV } from 'src/environments/environment';

import b from '../../../assets/data/banners.json';
import data from '../../../assets/data/social-proof.json';
import { SocialProof } from './../../shared/models/social-proof.model';
import { CatalogService } from '@infrab4a/connect-angular';


@Component({
  selector: 'glam-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  @ViewChild('content') myModal: any;

  products: Product[];
  result: any;
  reviewedProducts: Product[];
  loadingProducts: boolean;
  loadingReviewedProducts: boolean;
  isProduction = false;
  banners: Banner[];
  social: SocialProof[] = []
  reviewPoints: number[];
  projectId;
  source;
  innerWidth: number;
  companyValues = [
    {
      description: 'Alta Qualidade',
      svgPath: 'assets/img/our_value_1.svg',
    },
    {
      description: 'Vegano e cruelty free',
      svgPath: 'assets/img/our_value_2.svg',
    },
    {
      description: 'Rotina descomplicada',
      svgPath: 'assets/img/our_value_3.svg',
    },
    {
      description: 'Sem parabenos',
      svgPath: 'assets/img/our_value_4.svg',
    },
  ];

  isUltrawide = false;
  isTablet = false;
  isMobile = false;

  closeResult: string = '';
  formLead: FormGroup;
  loadingNewsletter: boolean;
  submitted = false;
  show = true;

  namePattern = '^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$';

  emailPattern = '^[a-zA-Z]+([\\.\\_\\-]?[a-zA-Z0-9])*@[a-zA-Z0-9]{2,}(\\.[a-zA-Z]{2,4}){1,2}$';

  user_email = '';

  category: Category;

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.styleSlider();
  }

  constructor(
    @Inject('ProductRepository') private _fireStore: ProductRepository,
    @Inject('CategoryRepository') protected categoryService: CategoryRepository,
    @Inject(PLATFORM_ID) private platform: object,
    private _resizeAppService: ResizeAppService,
    private meta: Meta,
    private titleService: Title,
    private modalService: NgbModal,
    private _brandLeadService: BrandLeadService,
    private fb: FormBuilder,
    private catalogService: CatalogService,
    @Inject('ShopSettingsRepository')
    private shopSettingsRepository: ShopSettingsRepository

  ) {
    this.innerWidth = window.innerWidth;
    this.innerWidth = window.innerWidth;
    this.loadingProducts = true;
    this.loadingReviewedProducts = true;

    this.isProduction = ENV.production;
  }

  ngAfterViewInit(){
    this.open(this.myModal);
    this.initForm();
    this.loadingNewsletter = false;
  }

  async ngOnInit(): Promise<void> {
    this.projectId = ENV.firebase.projectId;
    this.source = "Glam Beauty";
    this.banners = b.banners;
    this.preloadSocial();
    this.getReviewedProducts();
    this.styleSlider()
    this.styleComponentsB4a();

    this._resizeAppService
    .onResize()
    .subscribe((innerWidth) => (this.innerWidth = innerWidth));

    this.titleService.setTitle("Glam Beauty: produtos de alta qualidade e preço acessível");
    this.meta.addTag({
      name: 'author',
      content: 'B4A'
    });
    this.meta.addTag({
      name: 'description',
      content:
        'Glam Beauty oferece produtos com qualidade e acessíveis. Skincare, Maquiagem, Corpo e mais! Assinantes tem 40% OFF em qualquer compra.'
    });

    const gbeDocument = await this.shopSettingsRepository.get({
      id: 'GLAM_BEAUTY'
    });

    this.loadBanners(gbeDocument);
  }

  loadBanners(gbeDocument: ShopSettings) {
    this.banners = gbeDocument.sections.map((banner) => ({
      image: banner.desktopImage,
      mobileImage: banner.mobileImage,
      path: banner.link,
      alt: banner.altText,
      id: banner.id
    }));
  }

  async preloadSocial(): Promise<void> {

    const promises: Promise<void>[] = [];

    const firstSocial = data.social[0];

    await new Promise<void>((resolve) => {
      const image = new Image();
      image.src = firstSocial.imagePath;
      image.onload = () => resolve();
    });

    this.social = data.social;

    const [, ...rest] = data.social;

    rest.map(social => social.imagePath).forEach((imageUrl) => {
      const promise = new Promise<void>((resolve) => {
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => resolve();
      });

      promises.push(promise);
    });

    await Promise.all(promises).then(() => {});
  }

  handleSlideToSection(section: string): void {
    Utils.slideToSection(section);
  }

  styleComponentsB4a(): void {
    if (isPlatformBrowser(this.platform)) {
      const element = document.getElementsByClassName('mp-values');
      element[0].classList.add('container');
    }
  }

  styleSlider(): void {
    if (isPlatformBrowser(this.platform)) {
      this.isUltrawide = window.innerWidth > 2000;
      this.isTablet = window.innerWidth < 768;
      this.isMobile = window.innerWidth < 480;
    }
  }

  private filterUserReviewProduct(
    slug: string,
    reviewProduct: ProductReview
  ): ProductReview {
    if (
      reviewProduct.author.includes('Maju Pereira') &&
      slug.includes('espuma-de-limpeza-glam-beauty-glam-clean-150ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Amanda A Magalhães Rodrigues')  &&
      slug.includes('hidratante-com-protecao-solar-glam-beauty-glam-protect-50g')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Amanda Fournier')  &&
      slug.includes('serum-hidratante-glam-beauty-glam-lift-30ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Bianca Lopes')  &&
      slug.includes('espuma-de-limpeza-glam-beauty-glam-clean-150ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Julia Gonsalves')  &&
      slug.includes('gel-hidratante-glam-beauty-glam-nutri-60g')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Camila Vieira')  &&
      slug.includes('agua-micelar-glam-beauty-glam-fresh-200ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Laura Vera')  &&
      slug.includes('mascara-facial-glam-beauty-glam-detox-80g')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Louise Oliveira')  &&
      slug.includes('hidratante-com-protecao-solar-glam-beauty-glam-protect-50g')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Amanda A Magalhães Rodrigues')  &&
      slug.includes('hidratante-com-protecao-solar-glam-beauty-glam-protect-50g')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Taiza Silva')  &&
      slug.includes('esfoliante-corporal-glam-beauty-glam-scrub-glam-beauty-150ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Emanuelle Costa')  &&
      slug.includes('gel-hidratante-corporal-glam-beauty-glam-acqua-200ml')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Lilian Rodrigues')  &&
      slug.includes('sabonete-corporal-em-barra-glam-beauty-glam-bath')
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author.includes('Lilian Rodrigues ')  &&
      slug.includes('creme-hidratante-corporal-glam-beauty-glam-intense-150ml')
    ) {
      return reviewProduct;
    } else {
      return null;
    }
  }

  private async getReviewedProducts(): Promise<void> {
    const value = ['Glam Beauty', 'GLAM BEAUTY'];
    const productsSlugs = [
      'espuma-de-limpeza-glam-beauty-glam-clean-150ml',
      'hidratante-com-protecao-solar-glam-beauty-glam-protect-50g',
      'serum-hidratante-glam-beauty-glam-lift-30ml',
      'espuma-de-limpeza-glam-beauty-glam-clean-150ml',
      'gel-hidratante-glam-beauty-glam-nutri-60g',
      'agua-micelar-glam-beauty-glam-fresh-200ml',
      'mascara-facial-glam-beauty-glam-detox-80g',
      'hidratante-com-protecao-solar-glam-beauty-glam-protect-50g',
      'esfoliante-corporal-glam-beauty-glam-scrub-glam-beauty-150ml',
      'gel-hidratante-corporal-glam-beauty-glam-acqua-200ml',
      'sabonete-corporal-em-barra-glam-beauty-glam-bath',
      'creme-hidratante-corporal-glam-beauty-glam-intense-150ml'
    ];

    try {
      const reviewedProductsResponse = await this._fireStore.find(
        {
          filters: {
            slug: {
            operator: Where.IN,
            value: productsSlugs,
            },
            published: {
              operator: Where.EQUALS,
              value: true,
            },
          }, fields: ['id', 'name', 'slug', 'images', 'reviews']
        }
      );

      const filteredReviewedProducts = (product: Product) =>
        productsSlugs.includes(product.slug);

      let reviewedProducts: Product[] = reviewedProductsResponse.data
        .filter(filteredReviewedProducts);

      let reviews: Product[] = [];
      for (const i of reviewedProducts) {
        for (const j of i.reviews) {
          if (this.filterUserReviewProduct(i.slug, j)) {
            const review: Product = {
              id: i.id,
              sku: i.sku,
              name: i.name,
              images: i.images,
              reviews: [j],
              slug: i.slug,
              description: undefined,
              price: undefined,
              hasVariants: false,
              NCM: '',
              EAN: '',
              CEST: '',
              weight: 0,
              stock: {
                quantity: 0
              },
              costPrice: 0,
              published: false,
              brand: '',
              identifier: undefined,
              identifiersFields: [],
              toPlain: function () {
                throw new Error('Function not implemented.');
              },
              metadata: {
                title: '',
                description: ''
              },
              category: undefined,
              evaluation: undefined
            }
            reviews.push(review);
          }
        }
      }

      this.reviewedProducts = reviews;
      let qtd: number;
      if (this.isUltrawide) {
        qtd = this.reviewedProducts.length / 6;
      } else {
        qtd = this.reviewedProducts.length / 2;
      }
      const dots = [];
      for (let i = 0; i < qtd; i++) {
        dots.push(i)
      }
      this.reviewPoints = dots;
      this.loadingReviewedProducts = false;
    } catch (err) {
      console.error(err);
      this.loadingProducts = false;
    }
  }

  changePosition(arr, from, to) {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
  };

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  open(content:any) {
    this.modalService.open(content, {centered: true, size: 'xl'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  initForm(): void {
    this.formLead = this.fb.group({
      name: ['', Validators.compose([Validators.required, Validators.pattern(this.namePattern)])],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  get formLeadControl() {
    return this.formLead.controls;
  }

  getClass(formLeadControl) {
    if (formLeadControl === this.formLeadControl.name) {
      if (this.formLeadControl.name.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.name.dirty || this.submitted) &&
        (this.formLeadControl.name.errors?.required || this.formLeadControl.name.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    }
    if (formLeadControl === this.formLeadControl.email) {
      if (this.formLeadControl.email.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.email.touched || this.submitted) &&
        (this.formLeadControl.email.errors?.required || this.formLeadControl.email.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    }
    else {
      return;
    }
  }

  setObject() {
    return {
      name: this.formLead.value.name,
      email: this.formLead.value.email,
      source: LeadSourceEnum.glam_beauty,
      acceptsNewsletter: true,
    };
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.formLead.valid) {
      try {
        const requestPayload: LeadModel = this.setObject() as LeadModel;
        this.loadingNewsletter = true;
        await this._brandLeadService
          .registerNewsletter(requestPayload)
          .then((res: ResponseNewletter) => {
            res;
            this.submitted = false;
            this.user_email = requestPayload.email;
            this.formLead.reset();
          });
      } catch (error) {
        console.error(error);
        this.loadingNewsletter = false;
      } finally {
        this.loadingNewsletter = false;
        this.submitted = false;
        this.show = !this.show;
      }
    }
  }
}
