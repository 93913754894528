<div id="press-card">
    <div *ngIf="press.link.length >= 2">
        <a
            target="_blank"
            [href]="press.link"
        >
            <div class="img-container">
                <svg
                    width="43"
                    height="36"
                    viewBox="0 0 43 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M39.4173 3.27272C37.81 4.88889 36.3234 6.82828 34.9572 9.09091C33.6714 11.3535 32.8276 13.6162 32.4258 15.8788C35.3992 15.8788 37.8502 16.7273 39.7789 18.4242C41.7879 20.1212 42.7924 22.505 42.7924 25.5758C42.7924 28.5656 41.8683 31.0707 40.02 33.0909C38.252 35.0303 35.9216 36 33.0285 36C30.1355 36 27.7649 34.9899 25.9166 32.9697C24.1486 30.8687 23.2646 28.3232 23.2646 25.3333C23.2646 20.3232 24.4701 15.5151 26.8809 10.9091C29.2917 6.22222 32.3455 2.58586 36.0421 0L39.4173 3.27272ZM16.1526 3.27272C14.5454 4.88889 13.0587 6.82828 11.6926 9.09091C10.4068 11.3535 9.56301 13.6162 9.1612 15.8788C12.1346 15.8788 14.5856 16.7273 16.5143 18.4242C18.5233 20.1212 19.5278 22.505 19.5278 25.5758C19.5278 28.5656 18.6037 31.0707 16.7554 33.0909C14.9874 35.0303 12.6569 36 9.76391 36C6.8709 36 4.50024 34.9899 2.65193 32.9697C0.883975 30.8687 0 28.3232 0 25.3333C0 20.3232 1.20542 15.5151 3.61626 10.9091C6.02711 6.22222 9.08084 2.58586 12.7775 0L16.1526 3.27272Z"
                        fill="#F3E0FF"
                    ></path>
                </svg>
            </div>
            <div class="content">
                <p class="title">{{ press.text }}</p>
                <img
                    [src]="press.logo"
                    alt="Image press"
                >
            </div>
        </a>
    </div>
    <div *ngIf="press.link.length <= 1">
        <div class="img-container">
            <svg
                width="43"
                height="36"
                viewBox="0 0 43 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M39.4173 3.27272C37.81 4.88889 36.3234 6.82828 34.9572 9.09091C33.6714 11.3535 32.8276 13.6162 32.4258 15.8788C35.3992 15.8788 37.8502 16.7273 39.7789 18.4242C41.7879 20.1212 42.7924 22.505 42.7924 25.5758C42.7924 28.5656 41.8683 31.0707 40.02 33.0909C38.252 35.0303 35.9216 36 33.0285 36C30.1355 36 27.7649 34.9899 25.9166 32.9697C24.1486 30.8687 23.2646 28.3232 23.2646 25.3333C23.2646 20.3232 24.4701 15.5151 26.8809 10.9091C29.2917 6.22222 32.3455 2.58586 36.0421 0L39.4173 3.27272ZM16.1526 3.27272C14.5454 4.88889 13.0587 6.82828 11.6926 9.09091C10.4068 11.3535 9.56301 13.6162 9.1612 15.8788C12.1346 15.8788 14.5856 16.7273 16.5143 18.4242C18.5233 20.1212 19.5278 22.505 19.5278 25.5758C19.5278 28.5656 18.6037 31.0707 16.7554 33.0909C14.9874 35.0303 12.6569 36 9.76391 36C6.8709 36 4.50024 34.9899 2.65193 32.9697C0.883975 30.8687 0 28.3232 0 25.3333C0 20.3232 1.20542 15.5151 3.61626 10.9091C6.02711 6.22222 9.08084 2.58586 12.7775 0L16.1526 3.27272Z"
                    fill="#F3E0FF"
                ></path>
            </svg>
        </div>
        <div class="content">
            <p class="title">{{ press.text }}</p>
            <img
                [src]="press.logo"
                alt="Image press"
            >
        </div>
    </div>
</div>
