import { SocialProofCardComponent } from './components/social-proof-card/social-proof-card.component';
import { SocialProofComponent } from './components/social-proof/social-proof.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { EllipsisModule } from 'ngx-ellipsis';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { CategoriesComponent } from './components/categories/categories.component';
import { FooterComponent } from './components/footer/footer.component';
import {
    InstagramCarouselComponent
} from './components/instagram-carousel/instagram-carousel.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { PressCardComponent } from './components/press-card/press-card.component';
import { PressComponent } from './components/press/press.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductsComponent } from './components/products/products.component';
import { ReviewCardComponent } from './components/review-card/review-card.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { SliderBannerComponent } from './components/slider-banner/slider-banner.component';
import { CarouselModule, CategoriesModule, NewsletterModule, OwnBrandsValuesModule } from '@infrab4a/components-b4a';
import { AdviceComponent } from './components/advice/advice.component';
import { CategoriesSectionComponent } from './components/categories-section/categories-section.component';
import { CategoriesProductsComponent } from './components/categories-products/categories-products.component';

const COMPONENTS = [
  FooterComponent,
  NewsletterComponent,
  SliderBannerComponent,
  ProductCardComponent,
  ProductsComponent,
  ReviewCardComponent,
  ReviewsComponent,
  CategoriesComponent,
  InstagramCarouselComponent,
  PressComponent,
  PressCardComponent,
  SocialProofComponent,
  SocialProofCardComponent,
  AdviceComponent,
  CategoriesSectionComponent,
  CategoriesProductsComponent
];

@NgModule({
  declarations: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgbAccordionModule,
    NgbModalModule,
    EllipsisModule,
    BrowserAnimationsModule,
    CarouselModule,
    OwnBrandsValuesModule,
    CategoriesModule,
    NewsletterModule,
  ],
  exports: [
    COMPONENTS,
    NgbModule,
    NgbAccordionModule,
    NgbModalModule,
    EllipsisModule,
    CarouselModule,
    BrowserAnimationsModule,
  ],
})
export class SharedModule {}
