import { SocialProof } from '../../models/social-proof.model';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import * as componentsB4a from '@infrab4a/components-b4a';
import { carouselSocialProofDesktop } from '../../constants/slides';


@Component({
  selector: 'glam-social-proof',
  templateUrl: './social-proof.component.html',
  styleUrls: ['./social-proof.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SocialProofComponent implements OnInit {

  @Input()
  social: SocialProof[];

  @Input()
  isTablet = false;

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel?.pointNumbers;
      });
  }
  carouselTile: componentsB4a.B4aCarouselConfig;

  constructor() {
    this.carouselTile = carouselSocialProofDesktop;
  }

  ngOnInit(): void {
  }

  onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
    this.carouselIsFirst = myCarousel.isFirst;
    this.carouselIsLast = myCarousel.isLast;
    if (myCarousel.pointNumbers) {
        this.points = myCarousel.pointNumbers;
    } else {
        this.points = [];
    }
  }

}
